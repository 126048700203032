import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Cookies = ({ data }) => (
  <Layout>
    <SEO title="Politique d'utilisation des cookies" />

<div style={{
        alignItems: 'stretch',
        WebkitBoxAlign: 'stretch',
        margin: 'auto',
        padding: '30px',
     }}>

<h1>Politique d'utilisation des cookies</h1>

<p>
  L'utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation.<br/>
  En naviguant sur le site, il les accepte.<br/>
  Un cookie est un élément qui ne permet pas d'identifier l'utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site internet. L'utilisateur pourra désactiver ce cookie par l'intermédiaire des paramètres figurants au sein de son logiciel de navigation<br/>
</p>
</div>

  </Layout>
)

export default Cookies
